import React from 'react';
import { Card, ErrorContainer, PasswordInput, TextValueInput } from '../components';

interface PageFlowPromptLoginProps {
  title: string;
  logo: string;
  uid: string;
  routerPrefix: string;
  message?: string;
}

export function PageFlowPromptLogin({
  title,
  logo,
  message,
  uid,
  routerPrefix,
}: PageFlowPromptLoginProps): JSX.Element {
  return (
    <Card
      header={
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-medium leading-6 text-gray-900">{title}</h1>
          {logo.length > 0 && <img src={logo} className="object-contain h-12 w-30"></img>}
        </div>
      }
    >
      {message != null && (
        <div className="mb-4">
          <ErrorContainer message={message} />
        </div>
      )}
      <form method="post" action={`${routerPrefix ?? ''}/flow/${uid}/login`}>
        <TextValueInput name="username" title="Username" />
        <PasswordInput />
        <input
          className="mt-4 inline-flex items-center rounded-md border border-transparent bg-5minds-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-5minds-blue-900 focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2"
          type="submit"
          value="Login"
        />
      </form>
    </Card>
  );
}
