export const OIDC_SCOPE_NAMES = ['address', 'email', 'openid', 'phone', 'profile'];
export const OIDC_CLAIM_NAMES = [
  'address',
  'birthdate',
  'email',
  'email_verified',
  'family_name',
  'gender',
  'given_name',
  'locale',
  'middle_name',
  'name',
  'nickname',
  'phone_number',
  'phone_number_verified',
  'picture',
  'preferred_username',
  'profile',
  'updated_at',
  'website',
  'zoneinfo',
];
