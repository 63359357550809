import React from 'react';
import { Card, ListRenderer } from '../../components';

export type ConsentPageProps = {
  interaction: any;
  uid: any;
  routerPrefix: string;
};

export function ConsentPage({
  interaction: {
    prompt: { details },
    params,
  },
  uid,
  routerPrefix,
}: ConsentPageProps): JSX.Element {
  const confirmPreviouslyGivenAuthorization =
    [details.missingOIDCScope, details.missingOIDCClaims, details.missingResourceScopes].filter(Boolean).length === 0;

  const oidcScopes = new Set(details.missingOIDCScope as any);
  oidcScopes.delete('openid');
  oidcScopes.delete('offline_access');

  const oidcClaims = new Set(details.missingOIDCClaims as any);
  ['sub', 'sid', 'auth_time', 'acr', 'amr', 'iss'].forEach(Set.prototype.delete.bind(oidcClaims));

  const resourceScopes = Object.entries(details.missingResourceScopes as any);

  return (
    <Card header={<h2 className="text-2xl font-medium leading-6 text-gray-900">Authorize Scopes & Claims</h2>}>
      <div className="flex flex-col max-h-screen overflow-x-auto gap-4 -mt-4">
        {confirmPreviouslyGivenAuthorization && (
          <li>the client is asking you to confirm previously given authorization</li>
        )}
        {oidcScopes.size > 0 && <ListRenderer title="OpenID Connect Scopes" items={[...oidcScopes] as Array<string>} />}
        {oidcClaims.size > 0 && <ListRenderer title="OpenID Connect Claims" items={[...oidcClaims] as Array<string>} />}

        {resourceScopes.length > 0 &&
          resourceScopes.map(([indicator, scopes]) => (
            <ListRenderer title={`Scopes Source: ${indicator}`} key={indicator} items={scopes as Array<string>} />
          ))}

        {Array.isArray(params.scope) &&
          Array.isArray(details.missingOIDCScope) &&
          params.scope.includes('offline_access') && (
            <li key="offline_access">
              the client is asking to have offline access to this authorization
              {!details.missingOIDCScope ||
                (!details.missingOIDCScope.includes('offline_access') && <>(which you{"'"}ve previously granted)</>)}
            </li>
          )}
      </div>

      <form
        className="mt-4 w-full flex flex-row justify-end"
        autoComplete="off"
        action={`${routerPrefix ?? ''}/flow/${uid}/confirm`}
        method="post"
      >
        <input
          className="inline-flex items-center rounded-md border border-transparent bg-5minds-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-5minds-blue-900 focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2 hover:cursor-pointer"
          type="submit"
          value="OK"
          autoFocus
        />
      </form>
    </Card>
  );
}
