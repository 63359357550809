import { Claim } from '@5minds/processcube_authority_sdk';
import React, { ReactNode } from 'react';

type SelectClaimRendererProps = {
  claim: Claim;
  action: ReactNode;
};

export function SelectClaimRenderer({ claim, action }: SelectClaimRendererProps): JSX.Element {
  return (
    <div className="flex justify-between py-2 items-center">
      <div className="flex flex-col w-2/3 text-sm leading-6">
        <div className="flex gap-3 items-baseline">
          <div className="leading-6 font-semibold text-gray-700">{claim.name}</div>
          <p id="claim-type" className="text-gray-400 font-mono text-xs">
            {claim.type}
          </p>
        </div>
        <div className="text-gray-500 truncate">
          {claim.description && claim.description.length > 0 ? claim.description : 'No description available'}
        </div>
      </div>
      {action}
    </div>
  );
}
