import React, { useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { FrontendClaim, FrontendScope, ScopesSidebarItem, ScopesSidebarSubitem } from '../../../contracts';
import { ExpandTransition, ScopeRenderer, ToggleSwitch } from '../../../components';
import { OIDC_SCOPE_NAMES, getUrlParam, updateUrlParam } from '../../../infrastructure';

type GroupScopesRendererProps = {
  scopes: FrontendScope[];
  claims: FrontendClaim[];
  toggleScope: (name: string) => void;
};

function scopesAndClaimsToNavigation(scopes: FrontendScope[], claims: FrontendClaim[]): ScopesSidebarItem[] {
  const navigation: ScopesSidebarItem[] = scopes.map((scope) => {
    const scopeClaims: ScopesSidebarSubitem = [];
    scope.claims.forEach((claimName) => {
      const claim = claims.find((c) => c.name === claimName);
      if (claim) {
        scopeClaims.push({
          name: claim.name,
          enabled: claim.enabled,
        });
      }
    });

    return {
      name: scope.name,
      enabled: scope.enabled,
      claims: scopeClaims,
    };
  });

  return navigation;
}

export function GroupScopesRenderer(props: GroupScopesRendererProps): JSX.Element {
  const oidcScopes = OIDC_SCOPE_NAMES;

  const scopeItems = scopesAndClaimsToNavigation(props.scopes, props.claims);
  const scopeItemsFromOidc = scopeItems.filter((navItem) => oidcScopes.includes(navItem.name));
  const customScopeItems = scopeItems.filter((navItem) => !oidcScopes.includes(navItem.name));

  return (
    <div className="w-2/5 h-full overflow-y-auto border-r border-gray-200 bg-white py-4">
      <h3 className="mb-4 px-2 text-2xl font-bold leading-6 text-gray-900">Custom Scopes</h3>
      <nav className="flex flex-col space-y-1 bg-white px-2" aria-label="Sidebar">
        {customScopeItems.map((scope) => (
          <Disclosure as="div" key={scope.name} className="space-y-1">
            {({ open }) => <ScopeRenderer scope={scope} open={open} toggleScope={props.toggleScope} />}
          </Disclosure>
        ))}
      </nav>
      <hr className="pb-4"></hr>
      <h3 className="mb-4 px-2 text-2xl font-bold leading-6 text-gray-900">OIDC-Scopes</h3>
      <nav className="flex flex-col space-y-1 bg-white px-2 pb-4" aria-label="Sidebar">
        {scopeItemsFromOidc.map((scope) => (
          <Disclosure as="div" key={scope.name} className="space-y-1">
            {({ open }) => <ScopeRenderer scope={scope} open={open} toggleScope={props.toggleScope} />}
          </Disclosure>
        ))}
      </nav>
    </div>
  );
}
