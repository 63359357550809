import { DocumentTextIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from '../../../components';
import { Scope } from '@5minds/processcube_authority_sdk';

type SelectScopeRendererProps = {
  scope: Scope;
  currentScope: Scope;
  setCurrentScope: (scope: Scope) => void;
  setSelectedScope: (scope: Scope) => void;
  showUpdateDialog: () => void;
  showDeleteDialog: () => void;
};

export function SelectScopeRenderer(props: SelectScopeRendererProps): JSX.Element {
  const { scope } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const isSelectedScope = props.currentScope.name === scope.name;
  const hasDescription = scope.description && scope.description.length > 0;
  const isDefaultScope = scope.isDefaultScope;

  const [showDefaultEditScopeTooltip, setDefaultEditScopeTooltip] = useState(false);
  const [showDefaultDeleteScopeTooltip, setDefaultDeleteScopeTooltip] = useState(false);

  useEffect(() => {
    if (isSelectedScope) {
      divRef.current?.focus();
      divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <li>
      <div
        ref={divRef}
        tabIndex={0}
        onClick={() => props.setCurrentScope(scope)}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
            props.setCurrentScope(scope);
          }
        }}
        className={`flex justify-between items-baseline gap-x-3 rounded-md p-2 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2
        ${isSelectedScope ? 'bg-gray-100 outline-none ring-1 ring-5minds-blue-800 ring-offset-1' : 'hover:bg-gray-50'}`}
      >
        <div className="flex flex-col w-2/3">
          <div className="leading-6 font-semibold text-gray-700 hover:text-5minds-blue-900">{scope.name}</div>
          <div className="text-gray-500 truncate">
            {hasDescription ? scope.description : 'No description available'}
          </div>
        </div>
        <div className="flex gap-1">
          {scope.claims.length ? (
            <span
              className="w-9 mr-2 min-w-max h-fit whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
              aria-hidden="true"
            >
              {scope.claims.length}
            </span>
          ) : null}
          {!isDefaultScope ? (
            <>
              <a
                className="text-5minds-blue-800 hover:text-5minds-blue-900"
                title="Edit"
                onClick={() => {
                  props.setSelectedScope(scope);
                  props.showUpdateDialog();
                }}
              >
                <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              <a
                className="text-red-700 hover:text-red-800"
                title="Delete"
                onClick={() => {
                  props.setSelectedScope(scope);
                  props.showDeleteDialog();
                }}
              >
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            </>
          ) : (
            <>
              <Tooltip
                message={<p>Default scopes should not be altered!</p>}
                show={showDefaultEditScopeTooltip}
                setShow={setDefaultEditScopeTooltip}
              >
                <a className="text-gray-300 hover:text-gray-400" title="Edit">
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </Tooltip>
              <Tooltip
                message={<p>Default scopes should not be deleted!</p>}
                show={showDefaultDeleteScopeTooltip}
                setShow={setDefaultDeleteScopeTooltip}
              >
                <a className="text-gray-300 hover:text-gray-400" title="Delete">
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </li>
  );
}
