import React, { ReactNode } from 'react';

type SelectUserRendererProps = {
  showValue: string;
  action: ReactNode;
};

export function SelectUserRenderer({ showValue, action }: SelectUserRendererProps): JSX.Element {
  return (
    <div className="flex justify-between py-2 items-center">
      <div className="flex flex-col w-2/3 text-sm leading-6">
        <div className="flex gap-3 items-baseline">
          <div className="leading-6 font-semibold text-gray-700">{showValue}</div>
        </div>
      </div>
      {action}
    </div>
  );
}
