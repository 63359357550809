import React, { useEffect, useRef } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';

import { Group } from '@5minds/processcube_authority_sdk';

type SelectGroupRendererProps = {
  group: Group;
  currentGroup: Group;
  routerPrefix: string;
  setCurrentGroup: (group: Group) => void;
  setSelectedGroup: (group: Group) => void;
  showUpdateDialog: () => void;
  showDeleteDialog: () => void;
};

export function SelectGroupRenderer(props: SelectGroupRendererProps): JSX.Element {
  const { group } = props;
  const divRef = useRef<HTMLDivElement>(null);

  const isSelectedGroup = props.currentGroup.name === group.name;
  const hasDescription = group.description && group.description.length > 0;

  useEffect(() => {
    if (isSelectedGroup) {
      divRef.current?.focus();
      divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <li>
      <div
        ref={divRef}
        tabIndex={0}
        onClick={() => props.setCurrentGroup(group)}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
            props.setCurrentGroup(group);
          }
        }}
        className={`flex justify-between items-baseline gap-x-3 rounded-md p-2 hover:cursor-pointer focus:outline-none focus:ring-2 focus:ring-5minds-blue-900 focus:ring-offset-2
        ${isSelectedGroup ? 'bg-gray-100 outline-none ring-1 ring-5minds-blue-800 ring-offset-1' : 'hover:bg-gray-50'}`}
      >
        <div className="flex flex-col w-2/3">
          <div className="leading-6 font-semibold text-gray-700 hover:text-5minds-blue-900">{group.name}</div>
          <div className="text-gray-500 truncate">
            {hasDescription ? group.description : 'No description available'}
          </div>
        </div>
        <div className="flex gap-1">
          {group.users.length ? (
            <span
              className="w-9 mr-2 min-w-max h-fit whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
              aria-hidden="true"
            >
              {group.users.length}
            </span>
          ) : null}
          <>
            <a
              href={`${props.routerPrefix}/admin/group/${group.name}/edit`}
              className="text-5minds-blue-800 hover:text-5minds-blue-900"
            >
              <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Edit" />
              <span className="sr-only">, Edit {group.name}</span>
            </a>
            <a
              className="text-red-700 hover:text-red-800"
              title="Delete"
              onClick={() => {
                props.setSelectedGroup(group);
                props.showDeleteDialog();
              }}
            >
              <TrashIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </>
        </div>
      </div>
    </li>
  );
}
